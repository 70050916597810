@font-face {
  font-family: 'DM Sans';
  src: url('../../../assets/fonts/DM_Sans/DMSans-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'DM Sans';
  src: url('../../../assets/fonts/DM_Sans/DMSans-Bold.ttf');
  font-weight: bold;
}
@font-face {
  font-family: 'DM Sans';
  src: url('../../../assets/fonts/DM_Sans/DMSans-Italic.ttf');
  font-style: italic;
}
@font-face {
  font-family: 'DM Sans';
  src: url('../../../assets/fonts/DM_Sans/DMSans-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'DM Sans';
  src: url('../../../assets/fonts/DM_Sans/DMSans-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: 'DM Sans';
  src: url('../../../assets/fonts/DM_Sans/DMSans-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

h1 {
  font-size: var(--ion-font-size-38);
}
h2 {
  font-size: var(--ion-font-size-35);
}
h3 {
  font-size: var(--ion-font-size-31);
}
h5 {
  font-size: var(--ion-font-size-27);
}
h4 {
  font-size: var(--ion-font-size-23);
}
h6 {
  font-size: var(--ion-font-size-20);
}
