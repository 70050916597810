/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #d81e05;
  --ion-color-primary-rgb: 216, 30, 5;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #ac0404;
  --ion-color-primary-tint: #be0f0f;
  --ion-color-red2: #be0f0f;
  --ion-color-red3: #ac0404;

  /** secondary **/
  --ion-color-secondary: #526570;
  --ion-color-secondary-rgb: 82, 101, 112;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #2d373d;
  --ion-color-secondary-tint: #9cb0bc;

  --ion-color-grey2: #2d373d;
  --ion-color-grey3: #9cb0bc;
  --ion-color-grey3-opacity-65: #becbd3;
  --ion-color-grey4: #cccfd2;
  --ion-color-grey5: #f4f4f4;
  --ion-color-grey6: #d2d4cf;
  --ion-color-grey7: #eae9e9;
  --ion-color-grey8: #e8ebed;
  --ion-color-grey9: #f5f6f7;
  --ion-color-black-grey: #666666;
  --ion-color-box-shadow: #b4b4b43d;

  /** tertiary **/

  /** states **/
  --ion-color-green1: #008c47;
  --ion-color-green2: #749806;

  /** white **/
  --ion-color-white: #ffffff;

  /** error **/
  --da2a2a-color-error-principal: #da2a2a;
  --f6c9c9-color-error-fondo-mensaje: #f6c9c9;

  /** dark **/
  --ion-color-dark: #222428;

  /** success **/
  --ion-color-success: #008c47;
  --ion-color-success-rgb: 0, 140, 71;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #da2a2a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** Other colors **/
  --ion-color-orange: #e46b15;
  --ion-color-orange-light: #ffb37f;
  --ion-color-green: #0ca6b3;
  --ion-color-blue: #0d82bd;
  --ion-color-blue-light: #d2e8f3;
  --ion-color-pink-light: #f282c5;
  --ion-color-pink: #e6078c;
  --ion-color-brown-light: #c59672;
  --ion-color-brown2: #734b30;

  /** Font **/
  --ion-font-family: 'DM Sans';
  --ion-font-family-dmsans-bold: 'DMSans-Bold';
  --ion-font-family-dmsans-regular: 'DMSans-Regular';
  --ion-font-style-normal: normal;
  --ion-font-weight-normal: normal;
  --ion-font-weight-bold: bold;
  --ion-font-size-11: 11px;
  --ion-font-size-13: 13px;
  --ion-font-size-14: 14px;
  --ion-font-size-17: 17px;
  --ion-font-size-20: 20px;
  --ion-font-size-23: 23px;
  --ion-font-size-27: 27px;
  --ion-font-size-31: 31px;
  --ion-font-size-32: 32px;
  --ion-font-size-35: 35px;
  --ion-font-size-38: 38px;
  --ion-font-size-49: 49px;
  --ion-font-size-57: 57px;
  --ion-font-size-71: 71px;
  --ion-font-size-83: 83px;
  --ion-character-spacing-0: 0px;
  --ion-line-spacing-17: 17px;
  --ion-line-spacing-18: 18px;
  --ion-line-spacing-20: 20px;
  --ion-line-spacing-24: 24px;
  --ion-line-spacing-28: 28px;
  --ion-line-spacing-32: 32px;
  --ion-line-spacing-36: 36px;
  --ion-line-spacing-40: 40px;
  --ion-line-spacing-48: 48px;
  --ion-line-spacing-52: 52px;
  --ion-text-transform-uppercase: uppercase;

  /** Padding **/
  --ion-input-padding: 8px;
  --ion-background-color-body: #f5f6f7;
  --ion-background-color: #ffffff;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
