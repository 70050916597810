:root {
  //ION-ITEM
  ion-item {
    --background-hover: transparent;
    --background-focused: transparent;
    --inner-padding-end: 0px !important;
    ion-label {
      font-size: var(--ion-font-size-14);
      margin-top: 0;
      margin-bottom: 6px !important;
      color: var(--ion-color-secondary) !important;
      --color: var(--ion-color-secondary);
      letter-spacing: 0px;
    }

    ion-input,
    ion-select,
    mat-select,
    input,
    select {
      font-size: var(--ion-font-size-14);
      --placeholder-color: var(--ion-color-grey3);
      --placeholder-opacity: 1;
      --color: var(--ion-color-secondary);
      color: var(--ion-color-secondary);
      border: 1px solid var(--ion-color-grey4);
      border-radius: var(--ion-border-radius);
      --ripple-color: transparent;
      height: 42px;
    }

    select:not(:focus):invalid {
      color: var(--ion-color-grey3);
    }

    // Select
    mat-form-field span.mat-form-field-label-wrapper label {
      color: var(--ion-color-grey3) !important;
    }

    mat-form-field.mat-focused span.mat-form-field-label-wrapper label {
      color: var(--ion-color-grey3) !important;
    }

    mat-select {
      border: 1px solid var(--ion-color-grey4);
      padding: var(--ion-input-padding);
      font-size: var(--ion-font-size-14);
    }

    .mat-select-placeholder {
      color: var(--ion-color-grey3);
    }

    .mat-select-panel .mat-option,
    .mat-option.mat-focus-indicator.ng-star-inserted span.mat-option-text,
    span[id^='mat-option-'] {
      padding-left: var(--ion-input-padding);
      color: var(--ion-color-secondary) !important;
    }

    ion-select,
    input,
    mat-select {
      padding-left: var(--ion-input-padding) !important;
      padding-right: var(--ion-input-padding) !important;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--ion-color-grey3);
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--ion-color-grey3);
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--ion-color-grey3);
    }

    ion-input:not(.has-error):hover,
    mat-select:not(.has-error):hover,
    ion-select:not(.has-error):hover {
      border: 1px solid var(--ion-color-grey7);
      background-color: transparent;
    }

    ion-input:active,
    ion-input.has-focus,
    mat-select:active,
    mat-select:focus {
      border: 1px solid var(--ion-color-grey3);
      outline: none;
    }

    small {
      margin-top: 5px;
      letter-spacing: 0px;
      color: var(--da2a2a-color-error-principal);
    }

    .has-error {
      background-color: var(--f6c9c9-color-error-fondo-mensaje);
      color: var(--da2a2a-color-error-principal);
      border: 1px solid var(--da2a2a-color-error-principal);
    }
    .error-text {
      color: var(--da2a2a-color-error-principal);
    }
  }

  ion-checkbox {
    --background: transparent;
    --background-checked: transparent;
    --border-color: var(--ion-color-grey4);
    --border-color-checked: var(--ion-color-grey4);
    --checkmark-color: var(--ion-color-success);
    --border-radius: 2px;
    --border-width: 1px;
  }

  qb-input[no-padding] ion-input,
  qb-input[no-padding] input,
  qb-postal-code-multi[no-padding] ion-input,
  qb-postal-code-multi[no-padding] input {
    padding: 0px !important;
  }

  qb-input[no-border] ion-input,
  qb-input[no-border] input {
    border: 0px !important;
  }

  .mat-calendar-body-selected {
    background-color: var(--ion-color-primary) !important;
    color: var(--ion-color-white);
  }
}
