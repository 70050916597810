:root {
  //BUTTONS
  ion-button,
  ion-button[expand='full'] {
    border-radius: var(--ion-border-radius);
    text-transform: var(--ion-text-transform);
    --color: var(--ion-color-white);
    --border-width: 1px;
    --ripple-color: transparent;
    --box-shadow: 0;
    opacity: 1;

    &:hover {
      --background: var(--ion-color-red2);
    }
    &:active {
      --background: var(--ion-color-red3);
    }
    &[disabled] {
      --background: var(--ion-color-grey4);
    }

    &[fill='outline'] {
      --background: white;
      --color: var(--ion-color-grey2);
      --border-color: var(--ion-color-grey3);
      --background-hover-opacity: 0;
      --background-focused-opacity: 0;

      &:hover {
        --color: var(--ion-color-primary);
      }
      &:active {
        --color: var(--ion-color-red3);
      }
      &[disabled] {
        --color: var(--ion-color-grey4);
        --border-color: var(--ion-color-grey4);
      }
    }

    &.ion-color-secondary {
      --background: var(--ion-color-secondary);
    }
  }
}
