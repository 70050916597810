/* ----------- SWIPER ----------- */
swiper > .swiper.s-wrapper .swiper-pagination {
  pointer-events: none;
  width: 100%;
  top: 0px;
}
body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper-container {
  width: 100%;
  height: 100%;
}
swiper > .swiper.s-wrapper {
  padding-top: 28px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-pagination-bullet-active {
  background-color: var(--ion-color-primary) !important;
  border: var(--ion-color-primary) !important;
}
