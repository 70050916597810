/* ----------- MOVIL ----------- */
@mixin mobile {
  @media only screen and (max-width: 414px) {
    @content;
  }
}

/* ----------- TABLET ----------- */
@mixin tablet {
  @media only screen and (min-width: 415px) and (max-width: 1024px) {
    @content;
  }
}

/* ----------- DESKTOP ----------- */
@mixin desktop {
  @media only screen and (min-width: 1025px) {
    @content;
  }
}
