/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

// Base
@import './base/fonts.scss';
@import './base/angular-material.scss';
// Components
@import './components/buttons.scss';
@import './components/swipper.scss';
// Layout
@import './layout/form-components.scss';
@import './layout/modal.scss';
// Theme
@import './theme/ionic-variables.scss';
// Utilities
@import './utilities/mixins.scss';

u {
  text-decoration: none;
}

.content-with-footer {
  padding-top: 80px;
  padding-bottom: 80px;
  min-height: calc(100vh - 194px - 64px);
}
@media (max-width: 1490px) {
  .container {
    max-width: 1490px;
    padding-left: 64px;
    padding-right: 64px;
  }
}
@media (min-width: 1490px) {
}
body {
  --background: var(--ion-background-color-body);
}
ion-content {
  --background: var(--ion-background-color-body);
}

//Condor Typography
h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  color: var(--ion-color-grey2, #2d373d);
}
h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  color: var(--ion-color-grey2, #2d373d);
}
h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: var(--ion-color-grey2, #2d373d);
}
h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: var(--ion-color-grey2, #2d373d);
}
h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: var(--ion-color-grey2, #2d373d);
}
/* h6 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    color: var(--ion-color-grey2, #2d373d);
} */

/* table tr td {
    padding: 5px;
}

table {
    border-collapse: separate;
    border-spacing: 0;
    border: 0.5px solid $tasks-border-secondary;
    box-sizing: border-box;
    box-shadow: 0px 8px 10px rgba(180, 180, 180, 0.16);
    border-radius: 8px;
    tr {
        height: 66px;
        &:nth-child(1) {
            background-color: var(--ion-color-grey7, #eae9e9);
            height: 60px !important;
            td:nth-child(1) {
                border-top-left-radius: 8px;
            }
            td:last-child {
                border-top-right-radius: 8px;
            }
        }

        td {
            p {
                margin: 0;
            }
            color: var(--ion-color-grey2, #2d373d);
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            border-bottom: 1px solid var(--ion-color-grey7, #eae9e9);
            padding-right: 32px;
            &:nth-child(1) {
                padding-left: 32px;
            }
        }
        &:last-child {
            td {
                border: none;
            }
        }
    }
} */

p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: var(--ion-color-grey2, #2d373d);
}

a {
  color: var(--ion-color-grey2, #2d373d);
  cursor: pointer !important;
}
a:hover {
  color: var(--ion-color-primary, #d81e05) !important;
}
.content-with-footer-login {
  min-height: calc(100vh - 194px + 64px) !important;
}

//SCROLL
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-track-piece {
  height: calc(100% - 120px);
}

::-webkit-scrollbar-thumb {
  background: var(--ion-color-grey3, #9cb0bc);
  border-radius: 30px;
}
//buttons
.mat-button-base {
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 16px;
  border-radius: 8px !important;
  &.mat-primary {
    color: var(--ion-color-primary);
    background-color: var(--ion-color-white);
    border: 1px solid var(--ion-color-primary);
    &:hover:enabled {
      border: 1px solid var(--ion-color-primary);
      background-color: var(--primary-lighter-color);
      color: var(--text-primary-lighter-color);
    }
  }
}
.mat-button {
  &.mat-primary {
    color: var(--ion-color-primary);
    background-color: transparent;
    border: none;
    &:hover:enabled {
      border: none;
    }
    &:hover:disabled {
      border: none;
    }
  }
}
.mat-raised-button {
  &.mat-primary {
    color: var(--ion-color-white);
    background-color: var(--ion-color-primary);
    border: 1px solid var(--ion-color-primary);
    &:hover {
      border: none;
    }
    &:disabled {
      border: none;
    }
  }
}

//mat-card

.mat-card {
  background: #ffffff !important;
  border: 0.5px solid #dadada !important;
  box-sizing: border-box !important;
  box-shadow: 0px 8px 10px rgba(180, 180, 180, 0.16) !important;
  border-radius: 8px !important;
  padding: 32px 32px 56px 32px !important;
}

.mat-card-title {
  font-family: 'DM Sans' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 26px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.full-screen-modal {
  padding: 64px;
  &.cdk-overlay-pane {
    max-width: 100vw !important;
    max-height: 100vh;
  }
  height: 100%;
  width: 100%;
}

.select-modal {
  padding: 64px;
  &.cdk-overlay-pane {
    max-width: 80vw !important;
    max-height: 100vh;
  }
  width: 80vh;
}

.comment-modal {
  padding: 64px;
  &.cdk-overlay-pane {
    max-width: 100vw !important;
    max-height: 100vh;
  }
  height: 70%;
  width: 60%;
  overflow: hidden;
}

.drug-finder-modal {
  padding: 64px;
  &.cdk-overlay-pane {
    max-width: 100vw !important;
    max-height: 100vh;
  }
  height: 100%;
  width: 75%;
}

.title {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 52px;
}
.calculator {
  //Custom mat-form
  .mat-form-field {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          height: 40px;
          line-height: 11px;
          letter-spacing: inherit;
          display: flex !important;
          align-items: center !important;
          input {
            height: 100%;
            color: var(--ion-color-secondary, #526570) !important;
            &:hover {
              border: none;
            }

            &:focus {
              border: none;
            }
          }
        }
      }
    }
  }

  .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateY(0%) !important;
  }
  .mat-form-field-label-wrapper {
    left: -12px !important;
    top: -30px !important;
    color: var(--ion-color-secondary, #526570) !important;
    font-family: 'DM Sans' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 18px !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    top: 0px !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-flex {
    margin-top: 0px !important;
    height: 40px;
    line-height: 11px;
    letter-spacing: inherit;
    display: flex !important;
    align-items: center !important;
  }

  .mat-form-field-wrapper {
    margin: 0 !important;
    padding: 0;

    .mat-form-field-flex {
      .mat-form-field-outline {
        .mat-form-field-outline-start {
          border-bottom-left-radius: 2px;
          border-top-left-radius: 2px;
        }

        .mat-form-field-outline-end {
          border-bottom-right-radius: 2px;
          border-top-right-radius: 2px;
        }
      }

      .mat-form-field-infix {
        height: 40px;
        padding: 0;
        border-top: solid transparent 0px !important;

        .mat-select[ng-reflect-model='SELECTION'],
        .mat-select[ng-reflect-model='NUMBER'] {
          .mat-select-min-line {
            color: var(--ion-color-secondary, #526570);
          }
        }
        .mat-select {
          .mat-select-arrow {
            width: 15px;
            height: 11px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='13' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            border: 0;
          }
        }
      }
    }
  }
  .mat-form-field-disabled {
    background-color: var(--ion-color-grey9, #f5f6f7);
    border-color: var(--ion-color-grey4, #cccfd2);
    color: var(--ion-color-secondary, #526570);
  }

  .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
    border: 1px solid #cccfd2;
    border-radius: 2px;
  }

  .mat-label-input {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 4px;
    ion-icon {
      font-size: 16px !important;
      margin-left: 4px;
    }
  }

  .mat-error-input {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 75%;
    color: #da2a2a;
    padding-left: 12px;
    ion-icon {
      font-size: 16px !important;
      margin-left: 4px;
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-prefix,
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0 !important;
  }

  .mat-error {
    margin-top: 16px;
  }
  //custom mat-checkbox
  .mat-checkbox-inner-container {
    width: 24px !important;
    height: 24px !important;
  }

  .mat-checkbox .mat-checkbox-frame {
    border: 1px solid #cccfd2 !important;
  }
  .mat-checkbox:hover .mat-checkbox-frame {
    border: 1px solid #526570 !important;
  }

  .mat-checkbox.mat-accent {
    &.mat-checkbox-checked .mat-checkbox-background {
      background-color: #ffffff;
      border: 1px solid #cccfd2 !important;
    }
    &.mat-checkbox-checked:hover .mat-checkbox-background {
      background-color: #ffffff;
      border: 1px solid #526570 !important;
    }
    .mat-checkbox-checkmark-path {
      stroke: var(--ion-color-successs, #008c47) !important;
    }
  }
  //custom mat-checkbox
  .mat-pseudo-checkbox {
    width: 24px !important;
    height: 24px !important;
    background-color: #ffffff;
    border: 1px solid #cccfd2 !important;
  }

  .mat-pseudo-checkbox:hover {
    border: 1px solid #526570 !important;
  }

  .mat-pseudo-checkbox::after {
    color: var(--ion-color-successs, #008c47) !important;
  }
  .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
    background-color: #ffffff;
    border: 1px solid #cccfd2 !important;
  }
  .mat-pseudo-checkbox.mat-pseudo-checkbox-checked:hover {
    background-color: #ffffff;
    border: 1px solid #526570 !important;
  }

  //Custom toggle
  .mat-slide-toggle .mat-slide-toggle-bar {
    background-color: #d2d4cf;
    height: 16px;
  }
  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background: rgba(162, 198, 23, 0.4);
  }

  .mat-expansion-panel-header {
    overflow: visible;
    span.mat-content {
      overflow: visible;
    }
  }

  .mat-slide-toggle .mat-slide-toggle-thumb {
    background-color: #ffffff;
    height: 24px;
    width: 24px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14);
  }

  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: #a2c617;
  }

  .mat-slide-toggle-content {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    margin-left: 16px;
  }

  //Custom mat-radio-button
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--ion-color-successs, #008c47);
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    color: var(--ion-color-successs, #008c47);
    background-color: var(--ion-color-successs, #008c47);
  }

  .mat-radio-label-content {
    margin-right: 24px;
  }

  .footer-form {
    height: 104px;
    width: 100%;
    padding: 0px 64px;
    background: #ffffff;
    display: flex;
    align-items: center;
    button {
      height: 56px;
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      padding: 16px 42px;
    }
  }
}

:root {
  color-scheme: light only;
}

span.asterisk {
  color: red;
}
