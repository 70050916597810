// MODAL
.cdk-global-overlay-wrapper .cdk-overlay-pane {
  max-width: 90vw !important;
}
.cdk-overlay-backdrop.custom-backdrop-background.cdk-overlay-backdrop-showing {
  background-color: var(--ion-color-grey8);
  opacity: 0.84;
}
.mat-dialog-container {
  box-shadow: 0px 7px 10px var(--ion-color-box-shadow);
}
