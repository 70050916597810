// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.
$fontConfig: (
  display-4: mat-typography-level(112px, 112px, 300, 'DM Sans', -0.0134em),
  display-3: mat-typography-level(56px, 56px, 400, 'DM Sans', -0.0089em),
  display-2: mat-typography-level(45px, 48px, 400, 'DM Sans', 0em),
  display-1: mat-typography-level(34px, 40px, 400, 'DM Sans', 0.0074em),
  headline: mat-typography-level(24px, 32px, 400, 'DM Sans', 0em),
  title: mat-typography-level(20px, 32px, 500, 'DM Sans', 0.0075em),
  subheading-2: mat-typography-level(16px, 28px, 400, 'DM Sans', 0.0094em),
  subheading-1: mat-typography-level(15px, 24px, 500, 'DM Sans', 0.0067em),
  body-2: mat-typography-level(14px, 24px, 500, 'DM Sans', 0.0179em),
  body-1: mat-typography-level(14px, 20px, 400, 'DM Sans', 0.0179em),
  button: mat-typography-level(14px, 14px, 500, 'DM Sans', 0.0893em),
  caption: mat-typography-level(12px, 20px, 400, 'DM Sans', 0.0333em),
  input: mat-typography-level(inherit, 1.125, 400, 'DM Sans', 1.5px)
);

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3)
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10
);

// Compute font config
@include mat-core($fontConfig);

// Theme Config

body {
  --primary-color: #d81e05;
  --primary-lighter-color: #f3bcb4;
  --primary-darker-color: #c81203;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};

  --secondary-color: #4c4c4c;
  --secondary-color-contrast: #ffffff;
  --secondary-lighter-color: #646464;
  --secondary-darker-color: #2c2c2c;
  --text-secondary-color: #{$light-secondary-text};
  --text-secondary-lighter-color: #{$dark-secondary-text};
  --text-secondary-darker-color: #{$light-secondary-text};
}

$mat-primary: (
  main: #d81e05,
  lighter: #f3bcb4,
  darker: #c81203,
  200: #d81e05,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text
    )
);
$theme-primary: mat-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: var(--ion-color-secondary);
  --accent-lighter-color: var(--ion-color-secondary-tint);
  --accent-darker-color: var(--ion-color-secondary-shade);
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$dark-primary-text};
}

$mat-accent: (
  main: var(--ion-color-secondary),
  lighter: var(--ion-color-secondary),
  darker: var(--ion-color-secondary-shade),
  200: var(--ion-color-secondary-tint),
  // For slide toggle,
  contrast:
    (
      main: $light-text,
      lighter: $light-text,
      darker: $light-text
    )
);
$theme-accent: mat-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #da2a2a;
  --warn-lighter-color: #fbe6ca;
  --warn-darker-color: #e99335;
  --text-warn-color: #{$dark-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$dark-primary-text};
}

$mat-warn: (
  main: #da2a2a,
  lighter: #fbe6ca,
  darker: #e99335,
  // #E46868
  200: #da2a2a,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $dark-primary-text
    )
);
$theme-warn: mat-palette($mat-warn, main, lighter, darker);

$theme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat-dark-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include angular-material-theme($theme);

.theme-alternate {
  @include angular-material-theme($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming
// Handle buttons appropriately, with respect to line-height

.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';
  .mat-badge-content {
    font-family: 'DM Sans';
  }
}

//GAMA DE COLORES
$link-primary: #d81e05;
$color-primary: #2d373d;
$color-secondary: #838383;
$color-transp: transparent;
$app-primary: var(--primary-color);
$app-secondary: #4c4c4c;
$footer-primary: #a9a9a9;
$footer-Border: #e8ebed;
$dashboard-secondary: #108c47;
$dashboard-border: rgba(218 218 218 / 30%);
$tabs-indicator: #26a69a;
$tasks-background: #f5f6f7;
$tasks-border-primary: #dee2e6;
$tasks-border-secondary: #dadada;
$tasks-border-third: #c9c9c9;
$tasks-border-accordion: #f5f5f5;
$table-border-primary: #eaecef;

$agGrid-priprity-1: #00bec6;
$agGrid-priprity-2: #26c600;
$agGrid-priprity-3: #ddd400;
$agGrid-priprity-4: #cb7d16;
$agGrid-priprity-5: var(--primary-color);
$agGrid-checkbox: #747474;
$agGrid-checkbox-checked: #749806;

$filter-text: #505050;

//COLOR TEXT
$breadcrumb-primary: #37434c;
$text-primary: black;
$infor-primary: #0d82bd;
$tasks-input: #3f4142;
$placeholder: #9cb0bc;
$textInput: #212529;
$textError: #da2a2a;
$textDatepicker: #666666;
$text-title: rgba(0, 0, 0, 0.87);
$text-pos: #209a5d;
$text-neg: #e77d32;

//ICONS
$streamline-icon-single: #526570;

.text-red {
  color: var(--primary-color);
}

//BACKGROUNDS
$app-bg-primary: white;
$app-bg-color: #e8ebed;
$app-bg-error: #faf0f2;
$dashboard-bg-primary: #fff4ec;
$dashboard-bg-tasks: #dce5c1;
$infor-bg-primary: #d2e8f3;
$accordion-bg-primary: #eae9e9;
$orange: orange;
$table-bg-click-element: #efefef;
$table-bg-click-element-hover: whitesmoke;

//BOX-SHADOW
$box-shadow-primary: 0 8px 9px 0 rgb(240 207 210 / 30%), 0 3px 1px -2px rgb(240 207 210 / 12%), 0 1px 5px 0 rgb(240 207 210 / 20%);
$box-shadow-task-primary: 0px 7px 10px #b4b4b43d;
$box-shadow-task-accordion: 0px 8px 12px -3px rgb(0 0 0 / 6%), 0px 2px 2px 0px rgb(0 0 0 / 0%), 0px 1px 5px 0px rgb(0 0 0 / 0%);
$box-shadow-card-resumen: linear-gradient(270deg, #2d373d00 93%, #2d373d 100%) 0% 0% no-repeat padding-box;

//GRADIENTS
$tasks-gradient-primary: rgba(112, 112, 112, 0.78) 0%;
$tasks-gradient-secundary: rgba(122, 122, 122, 1) 2%;
$tasks-gradient-third: rgba(245, 246, 247, 1) 27%;
$tasks-gradient-quarter: #c7707070;

//BORDER
$border-primary: #707070;
$border-secondary: #ced4da;
$border-tertiary: #55646e;
$border-input: #cccfd2;
$border-focus: #ced3d6;
$border-invaild: #e46868;

//HOVER
$hover: #f5f6f7;
$btn-hover: #be0f0f;

//FOCUS
$btn-focus: #ac0404;

//DISABLE
$btn-disable: #d2d4cf;

//buttons
button:focus {
  outline: 0;
}

.mat-tooltip {
  color: white;
  background-color: #000;
}

a {
  &:focus {
    background-color: $color-transp !important;
  }
}

$custom-typography: mat-typography-config(
  $font-family: 'DM Sans'
);

@include angular-material-typography($custom-typography);

// Bootstrap Custom
